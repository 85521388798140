@use "../../styles/colors" as *;


.hamburgermenu{

    width:100vw;
    height:100dvh;
    display: flex;
    flex-direction: column;



    &__header{
        width:100vw;
        //no idea why... if adjusting header height, this height is now +1rem of header height
        height:7rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding:0rem 1rem;
    }

    &__close{
        color:white;
        font-size: 1.75rem;
        font-weight: lighter;
    }


    &__border{
        width:2.5rem;
        height:2.5rem;
        display:flex;
        justify-content: center;
        align-items: center;
        border:1px solid white;
    }

    &__list{
        height:calc(100dvh - 7rem);
        list-style-type: none;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding:3rem 0rem;

    }

    &__item{
        display: flex;
        align-items: center;
    }

    &__course{
        width:10rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    &__triangle__down{
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(171deg) brightness(106%) contrast(107%);
        width:10px;
        height: 10px;
        margin-left: 0.125rem;
    }

    &__triangle__up{
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(171deg) brightness(106%) contrast(107%);
        width:10px;
        height: 10px;
        margin-left: 0.125rem;
        transform: rotateX(180deg);
    }


    &__dropdown-container__hidden{
        display:none;
    }

    &__dropdown-list{
        list-style-type: none;
        font-size: 1.25rem;
    }

    &__dropdown-item{
        color:white;
        padding-top:0.75rem;
        
    }
    &__navlink{
        color: white;
        font-size:2rem;
    }
    
    &__active{
        color:$burger-active;
        font-size: 2rem;
    }



}