@use "./styles/fonts" as *;

*,
*::before,
*::after {
  box-sizing: border-box;
  margin:0%;
  padding:0%;
}

body{
  width:100%;
  height:100%;
  font-family:'Poppins', 'Roboto';
  margin:0;
  padding:0;
}

.keep__footer__down{
  display: flex;
  min-height: 100dvh;
  height:100%;
  flex-direction: column;
}

h1,
h2,
h3,
p {
  margin: 0;
  padding: 0;
}
