@use "../../styles/colors" as *;
@use "../../styles/mixins" as *;

.about{
    padding:1rem;

    &__title, &__subtitle{
        padding:1rem 0rem;
        font-size: 2rem;
        font-weight: 400;
    }

    &__description{
        padding-bottom: 1rem;
        
    }

    &__pastcourse{
        display: flex;
        padding-bottom: 0.5rem;
    }

    &__pastcourse__name{
        text-decoration: underline;
        cursor: pointer;
        padding-right: 1rem;

    }

    &__pastcourse__text{
        padding-left: 1rem;
    }

    &__pastcourse__city{
        font-style: italic;
        padding-left: 0.5rem;
    }




    @include desktop{
        padding: 2rem 4rem;
    }
    
}