@use "../../styles/colors" as *;
@use "../../styles/mixins" as *;

.header{
    background-color: $header-background;
    height:6rem;
    width:100dvw;
    padding:0rem 1rem;

    display: flex;
    justify-content: space-between;
    align-items: center;


    &__logo{
        font-size: 1.5rem;

        @include tablet{
            font-size: 2rem;
        }

        @include desktop{
            font-size: 3rem;
        }

        &:hover{
            cursor: default;
        }
    }

    &__nav-container{
        display: flex;
        min-width:2.5rem;
    }

    @include tablet{
        height:7.5rem;
    }

    @include desktop{
        padding:0rem 4rem;
    }

}

