@use "../../styles/colors" as *;
@use "../../styles/mixins" as *;

.contact{
    padding:1rem;

    &__title-container{
        display: flex;
        flex-direction: column;
        padding-bottom: 2rem;
    }

    &__title{
        padding:1rem 0rem;
        font-size: 2rem;
        font-weight: 400;
    }

    &__field-container{
        padding:0.5rem 0;
        display: flex;
        flex-direction: column;
        width:100%;
    }

    &__short-container{
        @include desktop{
            display: flex;
            justify-content: space-between;
        }
    }

    &__short{
        @include desktop{
            width:48.5%;
        }
    }

    &__label{
        padding:0rem 0.125rem;
    }

    &__field{
        padding:0rem 0.25rem;
        height:2rem;
        border-radius: 8px;
        border: 1px solid $form-border;

        &:focus{
            border:2px solid $form-border;
            outline: none;
        }

        &__modal{
            background-color: rgba(0, 0, 0, 0.2);
        }
    }

    &__message{
        padding:0.25rem 0.25rem;
        min-height:8rem;
        resize: initial;
    }

    &__button-container{
        padding:2rem 0rem;
        display: flex;
        justify-content: center;
    }

    &__button{
        height:3rem;
        width:15rem;
        border-radius: 8px;
        border:1px solid $form-border;
        background-color: white;
        box-shadow: 0 1px $form-border;
        font-size: 1.25rem;
        color:$form-border;

        @include desktop{
            width:20rem;
        }

        &:active{        
        box-shadow:none;
        transform: translateY(1px);
        }

        &__modal{
            display: none;
        }
    }

    &__modal{
        background-color: rgba(0, 0, 0, 0.2);
    }

    @include desktop{
        padding: 2rem 4rem;
    }
}