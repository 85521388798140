//background colors

$primary-background: #ffffff;
$hamburger-background:black;
$header-background: #FFFE42;
$footer-background:#FFFE42;



//text colors

$primary-text: black;
$secondary-text: #ffffff;
$accent-text: #326ABC;
$accent-text-border: #fffe42;
$burger-active:#fffe42;
$form-border:#326ABC;
