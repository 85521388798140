@use "../../styles/colors" as *;
@use "../../styles/mixins" as *;

.faq{

    &__box{
        padding:0.5rem 0;
    }

    &__question-container{
        display: flex;
    }

    &__triangle__down{
        width:6px;
        height:6px;
        margin-left:0.25rem;
        margin-bottom: 0.125rem;
    }

    &__triangle__up{
        width:6px;
        height:6px;
        margin-left:0.25rem;
        margin-bottom: 0.125rem;
        transform: rotateX(180deg);
    }

    &__answer-container__hidden{
        display: none;
    }

    &__answer-container{
        padding:0.25rem 0;
    }

    &__question{
        cursor: default;
    }

    &__answer{
        cursor: default;
        font-style: italic;
    }
}