@use "../../styles/colors" as *;
@use "../../styles/mixins" as *;

.footer{
    // background-color: $footer-background;
    background-color: #ebeced;
    margin-top:auto;
    padding:0rem 1rem;
    height: 12rem;
    width:100vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &__hidden{
        display: none;
    }

    &__social-container{
        padding-top:2.5rem;
        display: flex;
        justify-content: space-between;
        width:12rem;
        
        @include tablet{
            width:15rem;
            padding: 1.25rem 0;
        }

        @include desktop{
            width:15rem;
            padding:0 4rem;
        }
    }

    &__social-link{
        width:3.5rem;
        height:3.5rem;

        @include desktop{
            width:2rem;
            height: 2rem;
        }
    }

    &__social-icon{
        width:100%;
        @include desktop{
            width:2rem;
        }
    }

    &__copyright-container{
        display: flex;
        flex-direction: column;
        align-items: center;

        @include tablet{
            flex-direction: row;
        }
        @include desktop{
            padding-left: 4rem;
        }
    }

    &__text{
        font-size: 0.75rem;
        // color:white;

        @include tablet{
            font-size: 0.875rem;
        }
    }

    &__vertical-line{
        display: none;

        @include tablet{
            display: block;
            padding:0rem 1rem;
        }
    }

    &__privacy{
        text-decoration: underline;
        &:hover {
            cursor: pointer;
        }
    }

    @include tablet{
        height:max-content;
    }
    @include desktop{
        flex-direction: row-reverse;
        height:3rem;
    }
}