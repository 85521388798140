@use "../../styles/mixins" as *;

.hamburger{
    width:2.5rem;
    height:2.5rem;
    position: relative;
    display:flex;
    padding:0.25rem 0rem;
    justify-content: space-evenly;
    align-items: center;
    flex-flow:column nowrap;
    border:1.6px solid black;


    &__line{
        width: 1.25rem;
        height:0.1rem;
        border-radius: 10px;
        background-color: black;

    }



}

.hamburger__menu{
    background-color: black;
    position:fixed;
    width:100dvw;
    height:100dvh;
    top:0px;
    right:0px;
    bottom:0px;
    left:0px;
    display: flex;
    z-index: 2;

}