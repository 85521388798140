@use "../../styles/colors" as *;
@use "../../styles/mixins" as *;

.landing__mobile{
    background-color: black;
}
.landing__tablet{
    display: flex;
        //6rem is the height of the header. 
        //modify this if header height changes
    height:calc(100dvh - 6rem);


    &__event{
        width:100vw;
        overflow: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

    }
    
    &__photo-container{
        width:50vw;
        overflow: hidden;
        position: absolute;
        //6rem is the height of the header. 
        //modify this if header height changes
        height:calc(100vh - 6rem);

        @include tablet{
            height:calc(100vh - 7.5rem);
        }
    }

    &__photo{
        object-fit: cover;
        width:100%;
        height:100%;
        filter: brightness(55%);

        &:hover{
            filter: brightness(80%);
        }
    }
    
    &__text-container{
        position: relative;
        color: white;
        display: flex;
        flex-direction: column;
        height:40vh;
        width:50%;
        justify-content: space-between;

        @include tablet{
            padding:2rem 0rem 0rem 1rem;
        }
        
        @include desktop{
            padding:6rem 0rem 0rem 4rem ;
            max-height: 24rem;
        }

        &:hover{
            cursor:default;
        }
    }

    &__title{
        font-size: 3rem;
        font-weight: 400;
        line-height:100%;
        //might need a width css if the title no longer form two lines
    }

    &__date{
        font-size: 1.385rem;
        font-weight: lighter;
        font-style:italic ;
        line-height:110%;
        //might need to change the width if they no longer form two lines
        width:10rem;
        padding-bottom: 2rem;

        @include desktop{
            font-size:1.5rem;
        }
    }

    &__instructor{
        font-size: 1.5rem;
        font-weight:lighter;
        line-height:110%;
        //might need to change the width if they no longer form a single line
        width: 16.5rem;
    }

    &__learn-container{
        position: relative;
        display: flex;
        justify-content: center;
        margin:2rem 0;
    }

    &__learn{
        color:white;
        font-size: 2.5rem;
        border-bottom: 1px solid $accent-text-border;
        filter:brightness(70%);
        box-shadow: 0 3px $accent-text-border;

        &:hover{
            cursor: pointer;
            filter: brightness(100%);
        }
        &:active{        
            box-shadow:none;
            transform: translateY(3px);
    
        }
    }

    @include tablet{
        height:calc(100vh - 7.5rem);
    }


}