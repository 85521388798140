@use "../../styles/colors" as *;
@use "../../styles/mixins" as *;

.courses{

    &__container{
        padding-bottom: 2rem;
    }

    &__title{
        padding:0 1rem;
        line-height: 125%;

        @include tablet{
            width:50%;
            padding: 1rem 1rem 1rem 0 ;
        }
    }

    &__photo-container{
        width:100vw;

        @include tablet{
            padding-top: 1rem;
            padding-left: 1rem;
            width:50%;
            float:right;
        }

        @include desktop{
            width:40%;
            max-width: 30rem;
        }
    }

    &__photo{
        width:100%;
    }

    &__details-container{
        padding:0 1rem;

        @include tablet{
            padding:0;
        }
    }

    &__summary{
        padding:0 1rem;

        @include tablet{
            padding:1rem 0;
        }
    }

    &__points{
        padding: 0 2rem ;
    }

    &__apply-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding:2rem 1rem;
    }

    &__button-container{
        padding:1rem 0rem;
        display: flex;
        justify-content: center;
    }

    &__button{
        height:3rem;
        width:15rem;
        border-radius: 8px;
        border:1px solid $form-border;
        background-color: white;
        box-shadow: 0 1px $form-border;
        font-size: 1.25rem;
        color:$form-border;

        @include desktop{
            width:20rem;
        }

        &:active{        
        box-shadow:none;
        transform: translateY(1px);
    }
    }

    &__button__gray{
        background-color: lightgray;
        border: none;
        box-shadow: none;
        color:darkgray;

        &:active{
            transform: none;
        }
    }


    &__subtitle{
        font-size: 1.5rem;
        padding:1rem 1rem;

        @include tablet{
            padding: 1rem 0;
        }
    }

    &__repertoire{
        @include tablet{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

        }
    }

    &__repertoire__list{
        padding:0.5rem 1rem;

        @include tablet{
            padding: 0.5rem 0;
            width:30%;
        }
    }

    &__repertoire__composer{
        font-weight: 500;
    }

    &__repertoire__piece{
        font-style: italic;
    }

    &__orchestra__details{
        padding:0 1rem;

        @include tablet{
            padding: 0;
        }
    }

    &__schedule{
        padding: 0 1rem;
        
        @include tablet{
            padding:0;
        }
    }

    &__date-container{
        padding: 0.685rem 0;

        @include tablet{
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            width:100%;
        }
    }

    &__date, &__location-date{
        font-weight: 500;

        @include tablet{
            width:20%;
        }
        @include desktop{
            width:25%;
        }
    }

    &__time-container{
        @include tablet{
            width:80%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
        }

        @include desktop{
            width:75%;
        }
    }

    &__time{
        @include tablet{
            width:15vw;
        }

        @include desktop{
            width:25%;
        }
    }

    &__location{
        padding:0 1rem;

        @include tablet{
            padding:0;
        }
    }

    &__location-details{
        padding:0.5rem 0;
    }

    &__faq{
        padding: 0 1rem;

        @include tablet{
            padding:0;
        }
    }

    @include tablet{
        padding: 1rem;
    }

    @include desktop{
        padding: 2rem 4rem;
    }

}