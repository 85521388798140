@use "../../styles/colors" as *;
@use "../../styles/mixins" as *;

.modal{
    
    background-color: white;
    border-radius: 2px solid $accent-text-border;
    border-radius: 4px;
    padding:1rem;


    width:100%;
    height:fit-content;
    position:fixed;
    top:40%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__header{
        min-width: 2.5rem;
        display: flex;
        justify-content: flex-end;
    }

    &__close{
        color:$form-border;
        font-size: 1.75rem;
        font-weight: lighter;
    }


    &__border{
        width:2.5rem;
        height:2.5rem;
        display:flex;
        justify-content: center;
        align-items: center;
        border:1px solid $form-border;
    }

    &__message{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__text{
        font-size: 1rem;
        padding:0.25rem;
    }

    &__button-container{
        display: flex;
        justify-content: center;
    }

    &__button{
        height:3rem;
        width:15rem;
        border-radius: 8px;
        border:1px solid $form-border;
        background-color: white;
        box-shadow: 0 1px $form-border;
        font-size: 1.25rem;
        color:$form-border;

        @include desktop{
            width:20rem;
        }

        &:active{        
        box-shadow:none;
        transform: translateY(1px);
        }

    }

    @include tablet{
        width:100%;
        height:fit-content;
        position:fixed;
        top:40dvh;
    }

    @include desktop{
        width:50%;
        height:fit-content;
        position:fixed;
        margin-left:25%;
        margin-right: 25%;
        top:40dvh;
    }
    &__hidden{
        display: none;
    }
}