@use "../../styles/colors" as *;
@use "../../styles/mixins" as *;

.applypage{
    padding:1rem;
    display: flex;
    flex-direction: column;

    &__title-container{
        display: flex;
        flex-direction: column;

        @include desktop{
            width:100%;
        }

    }

    &__title{
        padding:1rem 0rem;
        font-size: 2rem;
        font-weight: 400;
    }

    &__procedure-container{
        padding-left: 1rem;
        padding-top:1rem;
    }

    
    &__button-container{
        padding:2rem 0rem;
        height:14rem;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        @include tablet{
            flex-direction: row;
            flex-wrap: wrap;
            height:10rem;
        }

        @include desktop{
            width:70%;
            justify-content: space-between;
        }

    }

    &__applytext{
        @include tablet{
            width:100vw;
            text-align: center;
        }
    }

    &__button{
        height:3rem;
        width:100%;
        border-radius: 8px;
        border:1px solid $form-border;
        background-color: white;
        box-shadow: 0 1px $form-border;
        font-size: 1.25rem;
        color:$form-border;

        @include tablet{
            width:45%;
        }

        @include desktop{
            width:45%;
        }

        &:active{        
        box-shadow:none;
        transform: translateY(1px);
        }
    }

    &__button__gray{
        background-color: lightgray;
        border: none;
        box-shadow: none;
        color:darkgray;

        &:active{
            transform: none;
        }
    }

    &__button__highlight{
        border:4px solid $form-border;
    }

    &__form{
        width:100%;
    }

    &__form__hidden{
        display:none;
    }

    @include desktop{
        padding: 2rem 4rem;
        align-items: center;
    }
}