@use "../../styles/colors" as *;

.carousel{
    display: flex;
    background-color: $accent-text-border;
    // height:100%;
    height:calc(100dvh - 6rem);
    overflow: hidden;
    position: relative;

    &__slide{
        width:100vw;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__photo-container{
        background-color:black;
        position: absolute;
        overflow: hidden;
        height:100%;

        // height:calc(100dvh - 6rem);
        width: 100%;
    }

    &__photo{
        object-fit: cover;
        width:100%;
        height:100%;
        filter:brightness(65%)
    }

    &__text-container{
        padding:2rem 0 0 1rem;
        position: relative;
        color: white;
        display: flex;
        flex-direction: column;
        height:35vh;
        width:50%;
        justify-content: space-between;
    }

    &__title{
        font-size: 2rem;
        font-weight: 400;
        line-height:100%;
        //might need a width css if the title no longer form two lines
    }

    &__date{
        font-size: 1.25rem;
        font-weight: lighter;
        font-style:italic ;
        line-height:110%;
        //might need to change the width if they no longer form two lines
        width:10rem;
        padding-bottom: 1.5rem;
    }

    &__instructor{
        font-size: 1.25rem;
        font-weight:lighter;
        line-height:110%;
        //might need to change the width if they no longer form a single line
        width: 16.5rem;
    }

    &__learn-container{
        position: relative;
        display: flex;
        justify-content: center;
        margin:2rem 0;
    }

    &__learn{
        color:white;
        font-size: 2rem;
        font-weight: 200;
        border-bottom: 1px solid $accent-text-border;
    }

}

.swiper {
    width: 100%;
    height: 100%;
    --swiper-pagination-color: white;
    --swiper-pagination-bullet-inactive-color: white;
  }

.swiper-button-next, .swiper-button-prev{
    color:white;
    opacity: 0.8;
}

.swiper-button-disabled{
    opacity:0.2 !important;
}

