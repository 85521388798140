@use "../../styles/colors" as *;
@use "../../styles/mixins" as *;


.instructors{

    &__container{
        @include tablet{
            display: flex;
            flex-wrap: wrap;
        }
    }

    &__headshot-container{
        height:100%;
        order:3;

        @include tablet{
            width:20%;
        }
    }

    &__headshot{
        width:100%;
    }

    &__name{
        font-size:2rem;
        padding:1rem 1rem;

        @include tablet{
            order:1;
            width: 100%;
            font-size: 3rem;
            padding:3rem 1rem 1rem 1rem;
        }

        @include desktop{
            padding:3rem 4.5rem 1rem 4.5rem;
        }
    }

    &__details{
        padding:0rem 1rem;

        @include tablet{
            order:2;
            width:60%;
        }

        @include desktop{
            padding:0rem 4.5rem;
        }
    }

    &__title-container{
        display: flex;
        flex-direction: column;
    }

    &__title{
        font-size: 1.25rem;
        font-weight: 500;
    }

    &__description{
        font-size: 1.25rem;
        font-style: italic;
        padding-bottom: 1rem;
        
    }

    &__bio-container{
        padding-bottom: 2rem;
    }

    &__bio{
        font-size: 1.125rem;
        padding-bottom: 1rem;
    }

    
}