@use "../../styles/colors" as *;
@use "../../styles/mixins" as *;

.privacy{
    padding:2rem 1rem;
    &__title{
        font-size: 2.5rem;
        font-weight: 500;
    }

    &__text{
        font-size:1rem;
        list-style-type: none;
        padding:0.25rem 0;

        @include desktop{
            font-size: 1.125rem;
        }
    }

    &__topic{
        font-size: 2rem;
        font-weight: 500;
        padding:1rem 0rem;
    }
    @include desktop{
        padding:3rem 4.5rem;
    }
}