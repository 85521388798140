@font-face {
    font-family: 'Poppins';
    src: url('../assets/fonts/Poppins-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
  }
  @font-face {
    font-family: 'Poppins';
    src: url('../assets/fonts/Poppins-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
  }
  @font-face {
    font-family: 'Poppins';
    src: url('../assets/fonts/Poppins-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
  }
  @font-face {
    font-family: 'Poppins';
    src: url('../assets/fonts/Poppins-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'Poppins';
    src: url('../assets/fonts/Poppins-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }