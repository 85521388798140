@use "../../styles/colors" as *;
@use "../../styles/mixins" as *;

a {
    color: $primary-text;
    text-decoration: none;
}


.navigation{

    position:relative;
    height:100%;
    width:57vw;

    &__item{
        display: flex;
        align-items: center;
    }


    &__triangle__down{
        width:6px;
        height:6px;
        margin-left:0.125rem;
    }

    &__triangle__up{
        width:6px;
        height:6px;
        margin-left:0.125rem;
        transform: rotateX(180deg);
    }


    &__courses{
        position: relative;
        &:hover{
            cursor: pointer;
        }

    }

    &__dropdown-container{
        background-color: $header-background;
        border: black 1px solid;
        width: 9rem;
        position: absolute;
        top:100%;
        display: flex;
        z-index: 2;
    }

    &__dropdown-container__hidden{
        display: none;
    }

    &__dropdown-list{
        list-style-type: none;
        display: flex;
        flex-direction: column;
        width: 100%;
        }

    &__dropdown-item{
       width: auto;
       margin: 0.25rem 0.25rem;

        &:hover{
            background-color: black;
            color:$header-background;
            cursor: pointer;
        }

        @include desktop{
            font-size: 1.125rem;
        }
    }


    &__active{
        border-bottom: 1px solid black;
    }

    &__list{
        list-style-type: none;
        display: flex;
        justify-content: space-between;
    }

    &__navlink{

        @include tablet{
            // font-size: 1.125rem;
            font-size: 1rem;
        }

        @include desktop{
            font-size: 1.25rem;
        }
    }

    &__apply{

        @include tablet{
            color:$header-background;
            background-color: black;
            padding:0.25rem 0.5rem;
            border:1px solid black;
            border-radius: 4px;
            box-shadow: 0 3px $accent-text-border;

            &:active{
                box-shadow: none;
                transform: translateY(3px);
            }
        }
    }

    &__apply__active{
        @include tablet{
            color:black;
            background-color: $header-background;
            padding:0.25rem 0.5rem;
            border:1px solid black;
            border-radius: 4px;
        }
    }

    @include desktop{
        width: 40vw;
    }

}